const scrollTo = (refOrElement) => () => {
  const scrollToElement = refOrElement?.current || refOrElement;
  if (scrollToElement) {
    const scrollToElementY = scrollToElement.offsetTop;
    window.scrollTo({
      top: scrollToElementY,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export default scrollTo;
