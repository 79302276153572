import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Flex, Divider } from '@chakra-ui/react';
import { FaTwitter } from 'react-icons/fa';
import Html from 'timelesstime-ui/components/html';
import Heading from 'timelesstime-ui/components/heading';
import CanonicalLink from 'timelesstime-ui/components/canonical-link';

export default ({ author }) => (
  <Box my={4}>
    <Flex justify="center">
      <GatsbyImage image={getImage(author.avatar)} alt={author.avatar.title} />
    </Flex>

    <Heading as="h2" mb={1}>
      {author.firstName} {author.lastName}
    </Heading>

    <Divider mb={4} />

    <Html source={author.fields.bioHtml} />

    <Box>
      <CanonicalLink to={`//twitter.com/${author.twitterHandle}`}>
        <FaTwitter /> @{author.twitterHandle}
      </CanonicalLink>
    </Box>
  </Box>
);
