import React from 'react';
import { Box, Text, Divider } from '@chakra-ui/react';
import { FaQuoteLeft, FaQuoteRight, FaStar, FaRegStar } from 'react-icons/fa';
import times from 'lodash/times';
import CanonicalLink from 'timelesstime-ui/components/canonical-link';

export default ({ review, ...props }) => (
  <Box as="blockquote" maxW="600px" px={4} {...props}>
    <Box
      fontSize="md"
      fontFamily="Montserrat, Tahoma, Verdana, Segoe, sans-serif"
    >
      <Text>
        <FaQuoteLeft
          style={{
            position: 'absolute',
            marginLeft: '-20px',
            marginTop: '-5px',
            color: 'gray.800',
          }}
        />
        {review.title}
        <FaQuoteRight
          style={{
            position: 'absolute',
            marginLeft: '5px',
            marginTop: '11px',
            color: 'gray.800',
          }}
        />
      </Text>
    </Box>
    <Box
      as="footer"
      fontSize="sm"
      fontFamily="Bree Serif, Tahoma, Verdana, Segoe, sans-serif"
      color="tt.darkBlue"
      mt={3}
    >
      {review.url && review.reviewLocation && (
        <CanonicalLink to={review.url} mb={2} fontSize="sm">
          Read the review on {review.reviewLocation}
        </CanonicalLink>
      )}
    </Box>

    {review.rating && (
      <Box color="orange" mt={2} mb={4}>
        {times(review.rating, (i) => (
          <FaStar key={i} />
        ))}
        {times(5 - review.rating, (i) => (
          <FaRegStar key={i} />
        ))}
      </Box>
    )}

    <Divider />
  </Box>
);
