import React from 'react';
import { useIntersection } from 'react-use';
import { FaArrowAltCircleUp } from 'react-icons/fa';
import { Flex, Fade, IconButton, useToken } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';

import scrollTo from '../../utils/scroll-to';

export default ({ intersectionRef }) => {
  const zIndex = useToken('zIndices', 'tooltip');
  const darkBlue = useToken('colors', 'tt.darkBlue');
  const white = useToken('colors', 'white');
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '25%',
    threshold: 1,
  });
  const scrollTop =
    typeof document !== 'undefined' ? scrollTo(document.body) : () => {};
  return (
    <Fade in={intersection && intersection.intersectionRatio < 1}>
      <Flex
        left="0"
        right="0"
        position="fixed"
        bottom="15px"
        zIndex={zIndex}
        justifyContent="center"
      >
        <IconButton
          aria-label="scroll to top"
          borderRadius="50%"
          color={darkBlue}
          background={tinycolor(white).setAlpha(0.85).toRgbString()}
          boxShadow={`0px 0px 5px 3px ${tinycolor(white)
            .setAlpha(0.95)
            .toRgbString()}`}
          _hover={{
            background: tinycolor(white).setAlpha(0.95).toRgbString(),
          }}
          onClick={() => scrollTop()}
          icon={<FaArrowAltCircleUp />}
        />
      </Flex>
    </Fade>
  );
};
