import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactGA from 'react-ga';
import {
  Container,
  Box,
  Flex,
  HStack,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';

import BgImage from 'timelesstime-ui/components/bg-image';
import SEO from 'timelesstime-ui/components/seo';
import ScrollTop from 'timelesstime-ui/components/scroll-top';
import { Organization as OrganizationStructuredData } from 'timelesstime-ui/components/json-ld';
import Heading from 'timelesstime-ui/components/heading';
import RichText from 'timelesstime-ui/components/rich-text';
import ButtonLink from 'timelesstime-ui/components/button-link';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import PageLayout from '../components/layout';
import ContentModal from '../components/content-modal';
import ReviewCard from '../components/review-card';
import AuthorCard from '../components/author-card';

const BookPage = ({ data: { book } }) => {
  const intersectionRef = useRef(null);
  const primaryRetailerUrl = book?.retailerLinks?.[0]?.url;
  const [prefaceModalOpen, setPrefaceModalOpen] = useState(false);
  const [contentModalOpen, setContentModalOpen] = useState(false);
  return (
    <PageLayout>
      <SEO
        title={book.title}
        keywords={book.metaKeywords}
        description={book.metaDescription}
        canonicalPath="/"
        thumbnail={book.jsonLdFeaturedImage}
      />
      <OrganizationStructuredData />

      <Box ref={intersectionRef}>
        <BgImage
          as="section"
          bg={getImage(book.splashImage)}
          preload
          adaptiveLoading
        >
          <Container maxW="container.md" pt={6} pb={4} px={2}>
            <Flex flexDir={['column', 'row']}>
              <Box display={['none', 'block']}>
                <GatsbyImage
                  image={getImage(book.heroImage)}
                  alt={book.heroImage.title}
                />
              </Box>
              <Box ml={[0, 8]} width={['100%', '50%', '60%']}>
                <Heading as="h1" color="white" fontSize={['2xl', '2xl', '3xl']}>
                  {book.leadInText}
                </Heading>
                <Heading as="h2" color="white" fontSize={['xl', 'xl', '2xl']}>
                  {book.leadInSecondaryText}
                </Heading>
              </Box>
            </Flex>
            <HStack spacing={4} justifyContent="center" mt={6} mb={8}>
              {primaryRetailerUrl && (
                <ButtonLink
                  to={primaryRetailerUrl}
                  colorScheme="orange"
                  title="Buy Now"
                  size="md"
                  py={2}
                  height={10}
                  paddingInlineStart={4}
                  paddingInlineEnd={4}
                >
                  Buy Now
                </ButtonLink>
              )}
              <CallMeBack
                callBackText="Contact the Authors"
                size="md"
                variant="outline"
              />
            </HStack>
          </Container>
        </BgImage>
      </Box>

      <Container as="article" maxW="container.lg" mt={4}>
        <Box id="summary">
          <Heading as="h1">Summary</Heading>
          <RichText content={book.summary} />
          <HStack spacing={4} justifyContent="center" my={12}>
            <Button
              title="Read the Preface"
              colorScheme="orange"
              onClick={() => {
                setPrefaceModalOpen(true);
                ReactGA.event({
                  category: 'preface-modal',
                  action: 'opened',
                });
              }}
            >
              Read the Preface
            </Button>
            <Button
              title="View the Preface"
              colorScheme="orange"
              variant="outline"
              onClick={() => {
                setContentModalOpen(true);
                ReactGA.event({
                  category: 'contents-modal',
                  action: 'opened',
                });
              }}
            >
              View the contents
            </Button>
          </HStack>
        </Box>

        <ContentModal
          title="Preface"
          label="Read the preface"
          isOpen={prefaceModalOpen}
          setIsOpen={setPrefaceModalOpen}
        >
          <RichText content={book.preface} />
          <RichText content={book.prefaceAddendum} />
        </ContentModal>
        <ContentModal
          title="Contents"
          label="View the contents"
          isOpen={contentModalOpen}
          setIsOpen={setContentModalOpen}
        >
          <RichText content={book.contents} />
          <RichText content={book.contentsAddendum} />
        </ContentModal>

        <Container as="section" id="reviews" maxW="container.lg" mt={4} mb={12}>
          <Heading as="h1">Reviews</Heading>
          <SimpleGrid columns={[1, 1, 2]} spacingX={6} spacingY={8}>
            {book.reviews.map((reviewNode) => (
              <ReviewCard key={reviewNode.id} review={reviewNode} />
            ))}
          </SimpleGrid>
        </Container>

        <Container as="section" id="aboutAuthors" maxW="container.lg" mt={4}>
          <Heading as="h1">About the Authors</Heading>
          <SimpleGrid columns={[1, 1, 2]} spacingX={6} spacingY={4}>
            {book.authors.map((authorNode) => (
              <AuthorCard key={authorNode.id} author={authorNode} />
            ))}
          </SimpleGrid>
        </Container>
      </Container>
      <ScrollTop intersectionRef={intersectionRef} />
    </PageLayout>
  );
};

export const query = graphql`
  query BookSinglePageBySlug($slug: String!) {
    book: contentfulBook(slug: { eq: $slug }) {
      title
      metaDescription
      metaKeywords
      heroImage {
        title
        gatsbyImageData(width: 200, placeholder: BLURRED, layout: CONSTRAINED)
      }
      splashImage {
        ...SplashImage
      }
      leadInText
      leadInSecondaryText
      summary {
        raw
      }
      preface {
        raw
      }
      prefaceAddendum {
        raw
      }
      contents {
        raw
      }
      contentsAddendum {
        raw
      }
      authors {
        id
        firstName
        lastName
        twitterHandle
        jobTitle
        avatar {
          title
          gatsbyImageData(
            width: 200
            height: 250
            placeholder: BLURRED
            layout: FIXED
          )
        }
        fields {
          bioHtml
        }
      }
      retailerLinks {
        url
        retailerName
      }
      reviews {
        id
        title
        reviewLocation
        person
        url
        rating
        fields {
          textHtml
        }
      }
    }
  }
`;

export default BookPage;
