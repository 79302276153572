import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

export default ({ isOpen, setIsOpen, title, children }) => (
  <Modal
    isOpen={isOpen}
    onClose={() => setIsOpen(false)}
    size="6xl"
    scrollBehavior="inside"
  >
    <ModalOverlay />
    <ModalContent m={8}>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{children}</ModalBody>
      <ModalFooter justifyContent="center">
        <Button colorScheme="orange" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
